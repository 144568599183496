<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 1.7021V4.8C8.5 5.22004 8.5 5.43006 8.58175 5.59049C8.65365 5.73161 8.76839 5.84635 8.90951 5.91825C9.06994 6 9.27996 6 9.7 6H12.7979M13 7.49117V12.9C13 14.1601 13 14.7902 12.7548 15.2715C12.539 15.6948 12.1948 16.039 11.7715 16.2548C11.2902 16.5 10.6601 16.5 9.4 16.5H4.6C3.33988 16.5 2.70982 16.5 2.22852 16.2548C1.80516 16.039 1.46095 15.6948 1.24524 15.2715C1 14.7902 1 14.1601 1 12.9V5.1C1 3.83988 1 3.20982 1.24524 2.72852C1.46095 2.30516 1.80516 1.96095 2.22852 1.74524C2.70982 1.5 3.33988 1.5 4.6 1.5H7.00883C7.55916 1.5 7.83432 1.5 8.09327 1.56217C8.32285 1.61729 8.54233 1.7082 8.74364 1.83156C8.9707 1.9707 9.16527 2.16527 9.55442 2.55442L11.9456 4.94558C12.3347 5.33473 12.5293 5.5293 12.6684 5.75636C12.7918 5.95767 12.8827 6.17715 12.9378 6.40673C13 6.66568 13 6.94084 13 7.49117Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
